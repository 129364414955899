<template>
    <ValidationObserver ref="wizardPage6" id="wizardPage6" name="wizardPage6">
        <div v-if="appData" class="finalisation-page">
            <policy-alert :app-data="appData" :is-read-only="isReadOnly" @alertSaved="$emit('alertSaved', $event)"
                @handleError="$emit('handleError', $event)" />

            <policy-summary :app-data="appData" />

            <instructions :instructions-text="instructions" class="mb-10" />

            <insurer-quote-table :app-data="appData" :show-finalisation-view="true"
                :is-read-only="isReadOnly || appData.statusFlags.quoteAccepted" v-on="$listeners">
            </insurer-quote-table>

            <!-- Hidden input to validate if a quote has been accepted -->
            <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }" name="quoteAccepted"
                v-slot="{ errors }">
                <input type="hidden" v-model="appData.statusFlags.quoteAccepted" />
                <span class="text-danger">{{
                    errors[0]
                    ? "Please accept one of the quotes as per client's choice."
                    : ""
                }}</span>
            </ValidationProvider>

            <div v-if="appData.statusFlags.quoteAccepted">
                <div class="card border-secondary mt-10">
                    <div class="card-body">
                        <h4 class="font-weight-bold text-dark mb-0 mt-0">
                            Confirmation to Insurer
                        </h4>
                        <button class="btn btn-primary mt-6" @click.prevent="handleConfirmationToInsurerEmail" :disabled="isReadOnly || appData.statusFlags.quoteAcceptanceSentToInsurer
                            ">
                            <i class="fas fa-envelope fa-md text-white pb-1"></i>
                            Email Confirmation to Insurer
                        </button>

                        <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                            name="appData.statusFlags.quoteAcceptanceSentToInsurer" v-slot="{ errors }">
                            <div class="row mt-5">
                                <span class="switch switch-sm switch-outline switch-icon switch-primary ml-4">
                                    <label v-show="!localStatusFlags.quoteAcceptanceSentToInsurer.saving">
                                        <input type="checkbox" v-model="localStatusFlags.quoteAcceptanceSentToInsurer.value"
                                            @change="flagUpdated('quoteAcceptanceSentToInsurer')" :disabled="isReadOnly || appData.statusFlags.readyForInvoicing
                                                " />
                                        <span></span>
                                    </label>
                                    <b-spinner variant="primary"
                                        v-if="localStatusFlags.quoteAcceptanceSentToInsurer.saving"></b-spinner>
                                </span>
                                <label class="col-form-label font-size-h5 ml-2">
                                    I confirm that I have sent the confirmation email to the insurer.
                                </label>
                            </div>
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>

            <div v-if="appData.statusFlags.quoteAcceptanceSentToInsurer" class="mt-10">
                <div class="card border-secondary">
                    <div class="card-body confirmation">
                        <Document document-name="Confirmation of Placement"
                            :document-data="appData.documents.confirmationOfPlacement"
                            :get-confirmation-of-placement-email-template-func="getConfirmationOfPlacementEmailTemplate"
                            :on-create-document="createConfirmationOfPlacement"
                            :processing-indicator="creatingConfirmationOfPlacement" :downloadEnabled="false"
                            :is-read-only="isReadOnly || appData.statusFlags.confirmationOfPlacementSent"
                            :isShowTitle="true">
                        </Document>

                        <!-- Hidden input to validate if the user created the renewal letter -->
                        <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                            name="appData.documents.confirmationOfPlacement.id" v-slot="{ errors }">
                            <input type="hidden" v-model="appData.documents.confirmationOfPlacement.id" />
                            <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>

                        <div v-if="appData.documents?.confirmationOfPlacement?.view_url">
                            <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                                name="appData.statusFlags.confirmationOfPlacementSent" v-slot="{ errors }">
                                <div class="row mt-10">
                                    <span class="switch switch-sm switch-outline switch-icon switch-primary ml-4">
                                        <label>
                                            <input type="checkbox" v-model="appData.statusFlags.confirmationOfPlacementSent"
                                                :disabled="isReadOnly || appData.statusFlags.readyForInvoicing
                                                    " @change="
        $emit(
            'updateServerTransactionStatus',
            'confirmationOfPlacementSent'
        )
        " />
                                            <span></span>
                                        </label>
                                    </span>
                                    <label class="col-form-label font-size-h5 ml-2">
                                        I confirm that I have sent the Confirmation of Placement to the client.
                                    </label>
                                </div>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Product Selection -->
            <div class="mt-10"
                v-if="appData.statusFlags?.confirmationOfPlacementSent && appData.acceptedQuote"">
                                                                                                                                                                                                                                                                                                                                                                                                                                <div class="
            card border-secondary">
                <div class="card-body">
                    <h4 class="card-title">Product Selection</h4>
                    <div class="row">
                        <div class="col-5 ml-4 pb-3">
                            <div class="mb-5">
                                <small>The same as the corresponding INSIGHT Class of
                                    Risk name, for the accepted Quote.</small>
                            </div>
                            <ValidationProvider rules="required" name="acceptedQuote.K_Product" v-slot="{ errors }">
                                <label class="form-label font-weight-bold">
                                    Product<span class="text-danger">*</span>
                                </label>
                                <div class="position-relative">
                                    <v-autocomplete v-model="appData.acceptedQuote.K_Product" :items="sortedInsurerProducts"
                                        :value="appData.acceptedQuote.K_Product" item-text="Name" item-value="id"
                                        :disabled="isReadOnly || appData.statusFlags.readyForInvoicing" return-object
                                        :menu-props="{ offsetY: true }" @input="handleProductChange"
                                        :loading="isSavingProduct">
                                        <!-- Selected item display -->
                                        <template v-slot:selection="{ item }">
                                            <div class="d-flex align-center" style="width: 100%">
                                                <span>{{ item.Name }}</span>
                                                <v-chip small class="ml-auto"
                                                    :color="item.K_Status === 'Active' ? 'green' : 'red'">
                                                    {{ item.K_Status }}
                                                </v-chip>
                                            </div>
                                        </template>
                                        <!-- Dropdown item display -->
                                        <template v-slot:item="{ item }">
                                            <v-list-item-content>
                                                <div class="d-flex align-center" style="width: 100%">
                                                    <span>{{ item.Name }}</span>
                                                    <v-chip small class="ml-auto"
                                                        :color="item.K_Status === 'Active' ? 'green' : 'red'">
                                                        {{ item.K_Status }}
                                                    </v-chip>
                                                </div>
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                                    <b-spinner v-if="isSavingProduct" class="position-absolute"
                                        style="right: 40px; top: 50%; transform: translateY(-50%)" small>
                                    </b-spinner>
                                </div>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Policy Number -->
        <div class="mt-10" v-if="appData.statusFlags?.confirmationOfPlacementSent">
            <div class="card border-secondary">
                <div class="card-body">
                    <h4 class="card-title">Policy Number</h4>
                    <div class="row">
                        <div class="col-3">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <label class="form-label font-weight-bold">
                                    New Policy Number<span class="text-danger">*</span>
                                </label>
                                <div class="d-flex">
                                    <input class="form-control" v-model="appData.acceptedQuote.K_PolicyNumber"
                                        :disabled="isReadOnly || appData.statusFlags.readyForInvoicing || isSavingPolicyNumber"
                                        placeholder="1234POL">
                                    <button class="btn btn-primary ml-3" @click="savePolicyNumber"
                                        :disabled="isReadOnly || appData.statusFlags.readyForInvoicing || isSavingPolicyNumber">
                                        <b-spinner small v-if="isSavingPolicyNumber"></b-spinner>
                                        <span v-else>Save</span>
                                    </button>
                                </div>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Special Invoicing Instructions -->
        <div class="mt-10" v-if="appData.statusFlags?.confirmationOfPlacementSent">
            <div class="card border-secondary">
                <div class="card-body">
                    <h4 class="card-title">Special Invoicing Instructions</h4>
                    <div class="row">
                        <div class="col-12">
                            <ValidationProvider rules="max:2000" name="specialInvoicingInstructions" v-slot="{ errors }">
                                <textarea class="form-control min-rows"
                                    v-model="appData.transaction.K_SpecialInvoicingInstructions"
                                    :disabled="isReadOnly || appData.statusFlags.readyForInvoicing"
                                    placeholder="Enter any special invoicing instructions here..."></textarea>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Warning Message -->
        <div class="mt-10 text-center" v-if="appData.statusFlags?.confirmationOfPlacementSent">
            <div class="alert alert-warning">
                <p class="mb-0 font-weight-bold">
                    ONCE CONFIRMED BELOW, YOU WILL NOT BE ABLE TO MAKE ANY FURTHER CHANGES
                    TO THIS RENEWAL.
                </p>
            </div>
        </div>

        <div v-if="appData.statusFlags.confirmationOfPlacementSent">
            <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                name="appData.statusFlags.readyForInvoicing" v-slot="{ errors }">
                <div class="row mt-10">
                    <span class="switch switch-sm switch-outline switch-icon switch-primary ml-4">
                        <label>
                            <input type="checkbox" v-model="appData.statusFlags.readyForInvoicing" :disabled="isReadOnly"
                                @change="saveInvoicingInstructions" />
                            <span></span>
                        </label>
                    </span>
                    <label class="col-form-label font-size-h5 ml-2">
                        I confirm that this renewal is ready to be submitted for
                        invoicing.
                    </label>
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
        </div>
        </div>
    </ValidationObserver>
</template>

<style scoped>
/* Component specific styles */
.form-control.min-rows {
    min-height: calc(1.5em * 6 + 2px) !important;
}

.confirmation {
    margin-top: -16px;
}

.v-list-item-content {
    padding: 8px 16px !important;
}
</style>

<style>
/* Divider styling */
.divider {
    margin: 16px 0 16px 16px !important;
    border-color: rgba(0, 0, 0, 0.12) !important;
}
</style>

<script>
import PolicySummary from "../../../common/PolicySummary";
import PolicyAlert from "../../../common/PolicyAlert";
import Instructions from "../../../common/Instructions";
import InsurerQuoteTable from "../../../common/InsurerQuoteTable";
import Document from "../../../common/Document";

export default {
    name: "FinalisationPage",

    components: {
        PolicySummary,
        PolicyAlert,
        Instructions,
        InsurerQuoteTable,
        Document,
    },

    props: {
        appData: Object,
        isReadOnly: Boolean,
    },

    data() {
        return {
            insurerProducts: [],
            localStatusFlags: {
                quoteAcceptanceSentToInsurer: {
                    value: this.appData.statusFlags.quoteAcceptanceSentToInsurer,
                    saving: false,
                },
            },
            creatingConfirmationOfPlacement: false,
            isSavingProduct: false,
            isSavingPolicyNumber: false,
        };
    },

    mounted() {
        this.getInsurerProducts();

        // Initialize K_Product if it exists in transaction but not in acceptedQuote
        if (this.appData?.acceptedQuote && !this.appData.acceptedQuote.K_Product &&
            this.appData?.transaction?.K_Product?.id) {
            this.appData.acceptedQuote.K_Product = this.insurerProducts.find(
                p => p.id === this.appData.transaction.K_Product.id
            );
        }

        // Initialize K_PolicyNumber with K_QuoteReference if not already set
        if (this.appData?.acceptedQuote && !this.appData.acceptedQuote.K_PolicyNumber &&
            this.appData.acceptedQuote.K_QuoteReference) {
            this.appData.acceptedQuote.K_PolicyNumber = this.appData.acceptedQuote.K_QuoteReference;
        }
    },

    methods: {
        isFirstNonStrataProduct(item) {
            if (!item) return false;

            // Get both indices
            const currentIndex = this.sortedInsurerProducts.findIndex(p => p.id === item.id);
            const firstNonStrataIndex = this.sortedInsurerProducts.findIndex(p => !p.Name.toLowerCase().includes('strata'));

            return currentIndex === firstNonStrataIndex && firstNonStrataIndex > 0;
        },

        async savePolicyNumber() {
            if (!this.appData?.acceptedQuote?.id) {
                return;
            }

            try {
                this.isSavingPolicyNumber = true;

                // Save the policy number to CRM
                await this.$renewalDataService.saveQuoteDetails({
                    id: this.appData.acceptedQuote.id,
                    K_PolicyNumber: this.appData.acceptedQuote.K_PolicyNumber
                });

                // Emit update events
                this.$emit("updateWizardAppDataString");
                this.$emit("saveAppData");

            } catch (error) {
                this.$emit("handleError", error);
            } finally {
                this.isSavingPolicyNumber = false;
            }
        },

        async handleProductChange(product) {
            if (!this.appData?.acceptedQuote) {
                return;
            }

            try {
                this.isSavingProduct = true;

                // Update acceptedQuote
                this.appData.acceptedQuote.K_Product = product;

                // Update the quote in CRM
                if (this.appData.acceptedQuote.id) {
                    await this.$renewalDataService.saveQuoteDetails({
                        id: this.appData.acceptedQuote.id,
                        K_Product: { id: product?.id }
                    });
                }

                // Emit update event
                this.$emit("updateWizardAppDataString");

                // Emit save event
                this.$emit("saveAppData");

            } catch (error) {
                this.$emit("handleError", error);
            } finally {
                this.isSavingProduct = false;
            }
        },

        getInsurerProducts() {
            this.$renewalDataService.getInsurerProducts()
                .then(({ data }) => {
                    if (data.result) {
                        this.insurerProducts = data.result;
                    }
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },
        flagUpdated: function (flagName) {
            let flagData = this.localStatusFlags[flagName];
            if (flagData) {
                flagData.saving = true;

                this.$renewalDataService
                    .updateTransactionStatus(flagName, flagData.value)
                    .then(({ data }) => {
                        let updatedStatus = data.result;
                        if (updatedStatus) {
                            // Update appData flags
                            this.appData.statusFlags[flagName] = flagData.value;

                            // Update transaction status
                            let txn = this.appData.transaction;
                            txn.K_Status = updatedStatus.K_Status;
                            txn.K_StatusDetails = updatedStatus.K_StatusDetails;
                            txn.K_NextStep = updatedStatus.K_NextStep;
                            txn.K_DueInWeek = updatedStatus.K_DueInWeek;
                            txn.K_WeekStartDate = updatedStatus.K_WeekStartDate;

                            // Fire event to update the global transaction status on the UI
                            this.$emit("statusUpdated");

                            this.$emit("updateWizardAppDataString");
                        }

                        flagData.saving = false;
                    })
                    .catch((error) => {
                        // Reset to original value
                        flagData.value = !flagData.value;
                        flagData.saving = false;
                        this.$emit("handleError", error);
                    });
            }
        },

        getConfirmationOfPlacementEmailTemplate: function () {
            this.$commonDataService
                .getEmailTemplate("SEND_CONFIRMATION_OF_PLACEMENT")
                .then(({ data }) => {
                    let emailTemplate = data.result;
                    const url = window.URL.createObjectURL(new Blob([emailTemplate]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        `cib-email-${new Date().getTime()}.eml`
                    );
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },

        createConfirmationOfPlacement: function () {
            this.creatingConfirmationOfPlacement = true;

            this.$renewalDataService
                .createConfirmationOfPlacement()
                .then(({ data }) => {
                    if (data.result) {
                        let confirmationOfPlacementInfo = data.result;
                        this.appData.documents.confirmationOfPlacement = {
                            id: confirmationOfPlacementInfo.id,
                            view_url: confirmationOfPlacementInfo.K_ConfirmationOfPlacementLink,
                        };
                        this.$emit("updateWizardAppDataString");
                    }
                    this.creatingConfirmationOfPlacement = false;
                })
                .catch((error) => {
                    this.creatingConfirmationOfPlacement = false;
                    this.$emit("handleError", error);
                });
        },

        handleConfirmationToInsurerEmail() {
            const acceptedInsurerId = this.appData.acceptedQuote?.K_Insurer?.id;
            const acceptedInsurerContact = this.appData.selectedInsurers?.find((insurer) => insurer.id === acceptedInsurerId)?.selectedContact;
            this.$emit("handleConfirmationToInsurerEmail", acceptedInsurerContact);
        },

        saveInvoicingInstructions: function () {
            if (!this.appData.statusFlags.readyForInvoicing) {
                this.flagUpdated("readyForInvoicing");
                return;
            }

            let txnData = {
                K_SpecialInvoicingInstructions:
                    this.appData.transaction.K_SpecialInvoicingInstructions,
            };
            this.$renewalDataService
                .updateTransactionData(txnData)
                .then((/*{ data }*/) => {
                    // Update the transaction status (also updates wizardAppDataString)
                    this.$emit("updateServerTransactionStatus", "readyForInvoicing");
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },
    },

    computed: {
        sortedInsurerProducts() {
            if (!this.insurerProducts?.length) return [];

            // Split into Strata and non-Strata products
            const strataProducts = [];
            const otherProducts = [];

            this.insurerProducts.forEach(product => {
                if (product.Name.toLowerCase().includes('strata')) {
                    strataProducts.push(product);
                } else {
                    otherProducts.push(product);
                }
            });

            // Sort each array alphabetically by Name
            strataProducts.sort((a, b) => a.Name.localeCompare(b.Name));
            otherProducts.sort((a, b) => a.Name.localeCompare(b.Name));

            // Concatenate arrays with Strata products first
            return [...strataProducts, ...otherProducts];
        },

        instructions: function () {
            return "Please accept one of the quotes provided to the client as per the Broker Advice. If the client accepts one of the proposed options directly from the Broker Advice, this page will be updated automatically.";
        },
    },
};
</script>
